import { Entry } from '@/lib/parsers/entries';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Box, { BoxProps } from '../ui/Box';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './AreaList.css';
import ListItem from './AreaList.ListItem';

type List = BoxProps<
  'div',
  {
    items?: Entry[];
    title?: string;
    ListWrapperProps?: FlexProps;
    ListProps?: FlexProps;
    ListItemProps?: BoxProps<'li'>;
  }
>;

export default function List({
  items,
  title,
  ListWrapperProps,
  ListProps,
  ListItemProps,
  ...props
}: List) {
  const len = items?.length;
  const third = len ? Math.ceil(len / 3) : 0;

  const firstPart = items?.slice(0, third);
  const secondPart = items?.slice(third, 2 * third);
  const thirdPart = items?.slice(2 * third);

  const nestedItems = [firstPart, secondPart, thirdPart];

  return (
    <Box cx={{ mY: '5xl' }} {...props}>
      {title && (
        <Txt as="h3" variant="h3">
          {title}
        </Txt>
      )}
      {items && !!items.length && (
        <Flex direction="row" wrap {...ListWrapperProps}>
          {nestedItems.map((nestedItem, n) => {
            return (
              <Flex key={n} as="ul" rowGap="xs" {...mergePropsClassName(ListProps, styles.list)}>
                {nestedItem?.map((item) => {
                  return item.uri && <ListItem key={item.id} {...{ item, ...ListItemProps }} />;
                })}
              </Flex>
            );
          })}
        </Flex>
      )}
    </Box>
  );
}
