'use client';

import { Entry } from '@/lib/parsers/entries';
import { useBreakpointMax } from '@/theme';
import { vars } from '@/theme/vars.css';
import { useTranslations } from 'next-intl';
import Container, { ContainerProps } from '../ui/Container';
import Txt from '../ui/Txt';
import UnstyledAccordion from '../UnstyledAccordion';
import List from './AreaList.List';

type AreaListProps = ContainerProps<
  'div',
  {
    services?: Entry<'service'>[];
    sectors?: Entry<'sector'>[];
    products?: Entry<'product'>[];
  }
>;

export default function AreaList({ services, sectors, products, ...props }: AreaListProps) {
  const t = useTranslations('common');
  const lg = useBreakpointMax('lg');

  const accordion = [
    { title: t('sectors'), items: sectors },
    { title: t('services'), items: services },
    { title: t('products'), items: products },
  ];

  return (
    <Container {...props}>
      {lg ? (
        <UnstyledAccordion
          BoxProps={{ cx: { mY: '5xl' } }}
          items={
            accordion?.map(({ items, title }, index) => {
              return {
                index,
                style: { borderColor: vars.palette.colors.neutralLight },
                LabelProps: {
                  children:
                    (
                      <Txt variant="h4" cx={{ mB: 'none' }}>
                        {title}
                      </Txt>
                    ) ?? '',
                },
                CaretProps: {
                  cx: { fontSize: 'h4' },
                  openIconProps: { cx: { fontSize: 'h4', color: 'accent' } },
                },
                BodyProps: {
                  index,
                  children: (
                    <List
                      items={items}
                      cx={{ mY: 'none', width: 'full' }}
                      ListWrapperProps={{
                        direction: 'column',
                        cx: { rowGap: 'xs' },
                      }}
                      ListProps={{
                        style: { width: '100%' },
                      }}
                      ListItemProps={{ cx: { width: 'full' } }}
                    />
                  ),
                },
              };
            }) ?? []
          }
        />
      ) : (
        <>
          <List title={t('sectors')} items={sectors} />
          <List title={t('services')} items={services} />
          <List title={t('products')} items={products} />
        </>
      )}
    </Container>
  );
}
